import React from "react";
import { registerStyle } from "app/utils/CriticalCSS";
import style from "./PictoRadio.scss";
import Radio from "app/pages/.shared/form/Radio";
import PropTypes from "prop-types";

class PictoRadio extends React.PureComponent {
	render() {
		const { field = {}, value, checked, id, name, icon, label, toggle } = this.props;

		return (
			<div className="picto-radio">
				<Radio
					field={field}
					name={name}
					id={id}
					value={value}
					checked={checked}
					toggle={toggle}
				>
					{icon}
					<div className="picto-radio__label">{label}</div>
				</Radio>
			</div>
		);
	}
}

PictoRadio.propTypes = {
	checked: PropTypes.bool,
	name: PropTypes.string,
	toggle: PropTypes.func,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	id: PropTypes.string.isRequired,
	field: PropTypes.object,
	icon: PropTypes.element,
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default registerStyle(style)(PictoRadio);
