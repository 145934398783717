import PropTypes from "prop-types";
import React from "react";
import LazyLoad from "react-lazyload";
import { photoPropTypes } from "app/utils/propTypes";
import { MEDIA_TYPE } from "app/constants";
import Video from "app/pages/.shared/Video/Video";
import BackgroundProgressiveImg from "app/pages/.shared/BackgroundProgressiveImg";
import { registerStyle } from "app/utils/CriticalCSS";
import style from "./photo-carousel.scss";
import Swiper from "react-id-swiper";
import isEqual from "lodash/isEqual";
import IconNext from "app/pages/.shared/static/icons/IconNext";
import IconPrevious from "app/pages/.shared/static/icons/IconPrevious";
import classNames from "classnames";
import get from "lodash/get";
import { RESOLUTION } from "./responsive/responsiveReducer";
import { sendTagOnSwipeProductCarouselMobile } from "app/utils/analytics";

const IMG_SIZES = {
	small: { width: 800 },
	medium: { width: 1024 },
	large: { width: 1500 },
	xlarge: { width: 1500 },
};

class PhotoCarousel extends React.Component {
	constructor(props) {
		super(props);
		this.updateSwiper = this.updateSwiper.bind(this);
		this.updateThumbnailSwiper = this.updateThumbnailSwiper.bind(this);
		this.state = {
			swiper: null,
			activeIndex: 0,
			loadIndex: 0,
			thumbnailSwiper: null,
			isBeginning: true,
			isEnd: false,
			slidesLength: 0,
		};
		this.slidePrev = this.slidePrev.bind(this);
		this.slideNext = this.slideNext.bind(this);
	}

	shouldComponentUpdate(nextProps, nextState) {
		return (
			!isEqual(nextProps.photos, this.props.photos) ||
			nextProps.isSlideshowMode !== this.props.isSlideshowMode ||
			nextState.swiper !== this.state.swiper ||
			nextState.activeIndex !== this.state.activeIndex ||
			nextState.loadIndex !== this.state.loadIndex ||
			this.state.isBeginning !== nextState.isBeginning ||
			this.state.isEnd !== nextState.isEnd
		);
	}

	updateSwiper(swiper) {
		const slidesLength = swiper.slides ? swiper.slides.length : 0;
		this.setState({ swiper: swiper, slidesLength });
	}

	updateThumbnailSwiper(swiper) {
		this.setState({ thumbnailSwiper: swiper });
	}

	slidePrev() {
		this.state.swiper.slidePrev();
	}
	slideNext() {
		this.state.swiper.slideNext();
	}

	render() {
		const { photos = [], resolution } = this.props;

		const swiperParams = {
			getSwiper: this.updateSwiper,
			pagination: {
				el: ".swiper-pagination",
				type: "fraction",
			},
			keyboard: {
				enabled: true,
			},
			on: {
				transitionEnd: () => {
					this.setState(state => {
						const isBeginning = get(state, "swiper.isBeginning");
						const isEnd = get(state, "swiper.isEnd");
						if (resolution === RESOLUTION.SMALL || resolution === RESOLUTION.MEDIUM) {
							sendTagOnSwipeProductCarouselMobile();
						}
						return {
							isBeginning: isBeginning,
							isEnd: isEnd,
						};
					});
				},
				slideChange: () => {
					if (this.state.swiper && this.state.thumbnailSwiper) {
						this.setState(state => {
							state.thumbnailSwiper.slideTo(state.swiper.activeIndex);

							return {
								activeIndex: state.swiper.activeIndex,
								loadIndex: state.swiper.activeIndex,
							};
						});
					}
				},
			},
		};

		const thumbnailSwiperParams = {
			getSwiper: this.updateThumbnailSwiper,
			spaceBetween: 10,
			touchRatio: 0.2,
			slideToClickedSlide: true,
			slidesPerView: 7,
			freeMode: true,
			freeModeSticky: true,
			preventClicksPropagation: false,
			on: {
				click: () => {
					if (typeof this.props.onThumbnailClick === "function") {
						this.props.onThumbnailClick();
					}
					if (this.state.swiper && this.state.thumbnailSwiper) {
						this.setState(state => {
							if (get(state, "thumbnailSwiper.clickedIndex")) {
								state.swiper.slideTo(state.thumbnailSwiper.clickedIndex);
								return {
									activeIndex: state.thumbnailSwiper.clickedIndex,
								};
							}
							return state;
						});
					}
				},
				transitionEnd: () => {
					this.setState(state => {
						if (get(state, "thumbnailSwiper.realIndex") >= state.loadIndex) {
							return {
								loadIndex: state.thumbnailSwiper.realIndex,
							};
						}
						return state;
					});
				},
			},
		};

		const photosNodes = photos.map((photo, key) => {
			// this is the trick to handle lazy loading: only show the 2 next img from the current slide position
			// On mount, only the first 2 images are loaded, then each time the user go to the next slide
			// we load the next 2 images
			if (this.state.swiper && key <= this.state.loadIndex + 8) {
				if (photo.type === MEDIA_TYPE.VIDEO) {
					return (
						<div key={key}>
							<LazyLoad once offset={300}>
								<Video
									url={photo.url}
									autoplay={photo.autoplay}
									videoType={photo.videoType}
								/>
							</LazyLoad>
						</div>
					);
				}

				return (
					<div key={key}>
						<div
							className={classNames(
								{
									"photo-carousel-slide--highlight":
										key === this.state.activeIndex,
								},
								"photo-carousel-slide"
							)}
						>
							<BackgroundProgressiveImg
								sizes={IMG_SIZES}
								thumbnail={photo.thumbnail}
								src={photo.url}
								alt={photo.alt}
								backgroundPosition={"center"}
							/>
						</div>
					</div>
				);
			}
			return <div key={key} />;
		});

		return (
			<React.Fragment>
				<div className="photo-carousel">
					<Swiper {...swiperParams}>{photosNodes}</Swiper>
					{this.state.slidesLength > 0 && (
						<p className="photo-carousel__bottom-indexes">
							{this.state.activeIndex + 1}/{this.state.slidesLength}
						</p>
					)}
					<div className="navlogs__carousel__button navlogs__carousel__button--next">
						{!this.state.isEnd && this.state.swiper && (
							<IconNext
								className=" photo-carousel-navigation icon icon-navigation icon-navigation-next"
								onClick={this.slideNext}
								data-cy="carousel-next-button"
							/>
						)}
					</div>
					<div className="navlogs__carousel__button navlogs__carousel__button--previous">
						{!this.state.isBeginning && this.state.swiper && (
							<IconPrevious
								className="  photo-carousel-navigation icon  icon-navigation icon-navigation-previous"
								onClick={this.slidePrev}
								data-cy="carousel-previous-button"
							/>
						)}
					</div>
				</div>
				<div className="photo-carousel-thumbnail">
					<Swiper {...thumbnailSwiperParams}>{photosNodes}</Swiper>
				</div>
			</React.Fragment>
		);
	}
}

PhotoCarousel.propTypes = {
	photos: PropTypes.arrayOf(photoPropTypes),
	isSlideshowMode: PropTypes.bool,
	resolution: PropTypes.string,
	onThumbnailClick: PropTypes.func,
};

export default registerStyle(style)(PhotoCarousel);
