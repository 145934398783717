import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import "./TotalNote.scss";

const TotalNote = ({ showChildrenNote }) => {
	return (
		<React.Fragment>
			{showChildrenNote && (
				<div className="total-note">
					<FormattedMessage id="price.detail.children.discount.note" />
				</div>
			)}
		</React.Fragment>
	);
};

TotalNote.propTypes = {
	showChildrenNote: PropTypes.bool,
};

export default React.memo(TotalNote);
