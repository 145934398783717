import React from "react";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import PropTypes from "prop-types";
import AmountContainer from "app/pages/.shared/AmountContainer";

import "./Total.scss";
import { PAYMENT_OPTIONS } from "app/constants";

const Total = ({
	isBookingEligibleForDeposit,
	total,
	isPagePayment = false,
	paymentMode,
	isPaymentSummaryLabel = false,
}) => {
	const is1xPaymentMode = paymentMode === PAYMENT_OPTIONS["1x"];
	const is2xPaymentMode = paymentMode === PAYMENT_OPTIONS["2x"];
	const is4xPaymentMode = paymentMode === PAYMENT_OPTIONS["4x"];

	return (
		<div className="total">
			<div
				className={classNames("total__label", {
					"total__label-deposit": isPagePayment && (is2xPaymentMode || is4xPaymentMode),
				})}
			>
				{isPagePayment &&
					is1xPaymentMode &&
					(isPaymentSummaryLabel ? (
						<FormattedMessage id="price.detail.total.label" />
					) : (
						<FormattedMessage id="payment.methods.full.label.simple" />
					))}
				{isPagePayment && (is4xPaymentMode || is2xPaymentMode) && (
					<FormattedMessage id="price.detail.total.label" />
				)}

				{!isPagePayment && <FormattedMessage id="price.detail.total.label" />}
			</div>
			<div
				className={classNames("total__value total__price-detail-item", {
					"total__value--de-emphasize":
						isBookingEligibleForDeposit ||
						(!isBookingEligibleForDeposit && is2xPaymentMode),
					"total__price-detail-item--de-emphasize":
						isPagePayment && !is1xPaymentMode && isBookingEligibleForDeposit,
					"total__price-detail-item-with-deposit":
						(isBookingEligibleForDeposit && isPagePayment && is2xPaymentMode) ||
						(isPagePayment && is4xPaymentMode) ||
						(isPagePayment && !isBookingEligibleForDeposit && is2xPaymentMode),
				})}
			>
				<AmountContainer amount={total} />
			</div>
		</div>
	);
};

Total.propTypes = {
	isBookingEligibleForDeposit: PropTypes.bool,
	total: PropTypes.number,
	isPagePayment: PropTypes.bool,
	paymentMode: PropTypes.string,
	isPaymentSummaryLabel: PropTypes.bool,
};

export default React.memo(Total);
