import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import AmountContainer from "app/pages/.shared/AmountContainer.jsx";
import Separator from "app/pages/.shared/Separator";
import PayNowContainer from "app/pages/.shared/PayNow/PayNowContainer";
import { CollapsableQuotationPriceDetailContainerMobile } from "app/pages/Booking/Quotation/PriceDetails/QuotationPriceDetailContainer";
import { PAYMENT_OPTIONS } from "app/constants";
import Total from "app/pages/.shared/PriceSummary/Total/Total";

import "./PriceSummary.scss";

const PaymentPriceSummary = ({
	total,
	mensualite2Fois,
	isBookingEligibleForDeposit,
	paymentMode,
	mensualiteCasino,
}) => {
	const is1xPaymentMode = paymentMode === PAYMENT_OPTIONS["1x"];
	const is2xPaymentMode = paymentMode === PAYMENT_OPTIONS["2x"];
	const is4xPaymentMode = paymentMode === PAYMENT_OPTIONS["4x"];

	const totalNode = (
		<Fragment>
			<div className="price-summary__item">
				<Total
					isBookingEligibleForDeposit={isBookingEligibleForDeposit}
					total={total}
					isPagePayment
					paymentMode={paymentMode}
				/>
			</div>

			{/* mobile */}
			<div className="price-summary__item">
				<div className="price-summary__price-details">
					<CollapsableQuotationPriceDetailContainerMobile
						isBookingEligibleForDeposit={isBookingEligibleForDeposit}
					/>
				</div>
			</div>
		</Fragment>
	);

	let payNowNode = false;
	if (is2xPaymentMode) {
		payNowNode = (
			<PayNowContainer
				{...{
					isPaymentPage: true,
					isBookingEligibleForDeposit,
					mensualite2Fois,
				}}
			/>
		);
	} else if (is4xPaymentMode) {
		payNowNode = (
			<div className="pay-now pay-now--payment">
				<div className="pay-now__label">
					<FormattedMessage id="my.bookings.pay.now.label" />
				</div>
				<div className="pay-now__pay-now-amount">
					<AmountContainer amount={mensualiteCasino.apport} />
				</div>
			</div>
		);
	}

	return (
		<div className="price-summary">
			{totalNode}

			{!is1xPaymentMode && <Separator margin="narrow" design="full" />}

			{payNowNode}
		</div>
	);
};

PaymentPriceSummary.propTypes = {
	total: PropTypes.number,
	isBookingEligibleForDeposit: PropTypes.bool,
	mensualite2Fois: PropTypes.object,
	mensualiteCasino: PropTypes.object,
	paymentMode: PropTypes.bool,
	isPagePayment: PropTypes.bool,
};

export default PaymentPriceSummary;
