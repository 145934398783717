import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Separator from "app/pages/.shared/Separator";
import PayNowContainer from "app/pages/.shared/PayNow/PayNowContainer";
import { CollapsableQuotationPriceDetailContainerMobile } from "app/pages/Booking/Quotation/PriceDetails/QuotationPriceDetailContainer";
import Total from "app/pages/.shared/PriceSummary/Total/Total";
import TotalNote from "app/pages/.shared/PriceSummary/TotalNote/TotalNote";
import "./PriceSummary.scss";
import IconPaiement4x from "app/pages/.shared/static/icons/IconPaiement4x";
import { FormattedMessage } from "react-intl";

const QuotationPriceSummary = ({
	hasChildren,
	isPayment4xDisponible,
	total,
	isBookingEligibleForDeposit,
	style,
}) => {
	return (
		<div className="price-summary" style={style}>
			<Fragment>
				<div className="price-summary__item">
					<Total
						isBookingEligibleForDeposit={isBookingEligibleForDeposit}
						total={total}
					/>
				</div>
				<TotalNote showChildrenNote={hasChildren} />
				<div className="price-summary__item">
					<div className="price-summary__price-details">
						<CollapsableQuotationPriceDetailContainerMobile
							isBookingEligibleForDeposit={isBookingEligibleForDeposit}
						/>
					</div>
				</div>
			</Fragment>

			{isPayment4xDisponible && (
				<React.Fragment>
					<Separator margin="narrow" design="full" />
					<div className="price-summary__item price-summary__item--payment-method">
						<IconPaiement4x className="icon" width={30} height={20} />
						<FormattedMessage id="general.paiement.4x.available" /> *
					</div>
				</React.Fragment>
			)}

			{isBookingEligibleForDeposit && (
				<React.Fragment>
					<Separator margin="narrow" design="full" />
					<PayNowContainer
						{...{
							isPaymentPage: false,
							withDepositIcon: true,
							isBookingEligibleForDeposit,
						}}
					/>
				</React.Fragment>
			)}
		</div>
	);
};

QuotationPriceSummary.propTypes = {
	isBookingEligibleForDeposit: PropTypes.bool,
	total: PropTypes.number,
	style: PropTypes.object,
	hasChildren: PropTypes.bool,
	isPayment4xDisponible: PropTypes.bool,
};

export default React.memo(QuotationPriceSummary);
