import { connect } from "react-redux";
import {
	getQuoteTotalWithDelta,
	isBookingEligibleForDeposit,
} from "app/pages/Booking/bookingSelectors";
import {
	calculatePartialPaymentAmount,
	getEcheancierCasino,
} from "app/pages/Booking/Payment/paymentSelector";
import PaymentPriceSummary from "app/pages/.shared/PriceSummary/PaymentPriceSummary";

const mapStateToProps = state => {
	return {
		isBookingEligibleForDeposit: isBookingEligibleForDeposit(state),
		total: getQuoteTotalWithDelta(state),
		paymentType: state.booking.paymentType,
		paymentMode: state.booking.paymentMode,
		mensualite2Fois: calculatePartialPaymentAmount(state),
		mensualiteCasino: getEcheancierCasino(state),
	};
};

export default connect(mapStateToProps)(PaymentPriceSummary);
