import React from "react";
import PropTypes from "prop-types";
import { Redirect, Route } from "react-router-dom";
import { isAuthenticated } from "app/pages/Auth/authSelectors";

import { connect } from "react-redux";

const PrivateRoute = ({ component: Component, authPath, isAuthenticated, ...rest }) => {
	return (
		<Route
			{...rest}
			render={props =>
				// TODO ROUTER isAuthenticated renvoie toujours false en SSR
				isAuthenticated ? (
					<Component {...props} />
				) : (
					<Redirect
						to={{
							pathname: authPath,
							search: `?onauthsuccess=${props.location.pathname}`,
						}}
					/>
				)
			}
		/>
	);
};

function mapStateToProps(state) {
	return { isAuthenticated: isAuthenticated(state) };
}
export default connect(mapStateToProps)(PrivateRoute);

PrivateRoute.propTypes = {
	isAuthenticated: PropTypes.bool.isRequired,
	component: PropTypes.elementType,
	authPath: PropTypes.string,
};
