import React from "react";
import PropTypes from "prop-types";
import "./ProductNameWithCategory.scss";
import Category from "app/pages/.shared/Category";
import { PRODUCT_CATEGORY_TYPES } from "app/constants";
import CategoryTrident from "app/pages/.shared/CategoryTrident";

const ProductNameWithCategory = ({ name, category, categoryType }) => {
	let categoryElement = <Category category={category} />;

	if (categoryType === PRODUCT_CATEGORY_TYPES.TRIDENT) {
		categoryElement = <CategoryTrident category={category} />;
	}

	return (
		<div className="product-name-with-category">
			<span className="product-name-with-category__name" title={name}>
				{name}
			</span>
			<span className="product-name-with-category__category">{categoryElement}</span>
		</div>
	);
};

ProductNameWithCategory.propTypes = {
	name: PropTypes.string,
	category: PropTypes.number,
	categoryType: PropTypes.oneOf([PRODUCT_CATEGORY_TYPES.TRIDENT, PRODUCT_CATEGORY_TYPES.STAR]),
};

export default React.memo(ProductNameWithCategory);
