import { createSelector } from "reselect";

export const getPushNotifs = state =>
	state.pushNotification && state.pushNotification.pushNotifications;

export const getPushNotifications = createSelector(
	[getPushNotifs],
	(pushNotifications = []) => {
		return pushNotifications;
	}
);
