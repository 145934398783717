import PropTypes from "prop-types";
import React from "react";

const PICTO_BASE_URL = "https://res.cloudinary.com/perfectstay/image/upload/v1476279898/highlight";

const Picto = ({ pictoCode, white = false }) => {
	const suffix = white ? "W" : "G";

	return (
		<i
			style={{
				display: "block",
				height: "30px",
				width: "30px",
				backgroundImage: `url("${PICTO_BASE_URL}/${pictoCode}_${suffix}.svg")`,
				backgroundRepeat: "no-repeat",
				backgroundPosition: "50% 50%",
				backgroundSize: "contain",
			}}
		/>
	);
};

Picto.propTypes = {
	pictoCode: PropTypes.string,
	white: PropTypes.bool,
};

export default Picto;
