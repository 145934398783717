import React from "react";
import classNames from "classnames";
import get from "lodash/get";
import Collapse, { Panel } from "rc-collapse";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import IconArrowUp from "app/pages/.shared/static/icons/IconArrowUp";
import "./PushNotification.scss";

class PushNotificationComponent extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			expanded: false,
			animate: true,
		};
		this.onChange = this.onChange.bind(this);
	}

	onChange() {
		const expanded = this.state.expanded;

		this.setState({
			expanded: !expanded,
			animate: false,
		});
	}

	render() {
		const activeKey = this.state.expanded ? "0" : undefined;

		const pushClassname = classNames({
			push: true,
			"push--opened": this.state.expanded,
			"push--animate": this.state.animate,
		});

		const {
			backgroundColor,
			picto,
			title = [],
			content = [],
			promoCode,
			note = [],
			buttonUrl,
		} = this.props;
		return (
			<div
				className={pushClassname}
				style={{
					backgroundColor: backgroundColor,
				}}
			>
				<Collapse onChange={this.onChange} activeKey={activeKey}>
					<Panel
						header={
							<div className="push-header">
								<div className="push-header__picto">
									{picto && (
										<i
											className="icon"
											style={{
												backgroundImage: `url(${picto})`,
											}}
										/>
									)}
								</div>
								<div className="push-header__text">
									{title.map(titleLine => (
										<div className="push-header__title" key={titleLine}>
											{titleLine}
										</div>
									))}
								</div>
								<div className="push-header__arrow">
									<IconArrowUp height={20} width={20} />
								</div>
							</div>
						}
					>
						<div className="push__content">
							{content.map(contentLine => (
								<div key={contentLine} className="push__line push__line__content">
									{contentLine}
								</div>
							))}

							{promoCode && (
								<div className="push__line">
									<div className="push__code">
										<span style={{ userSelect: "none" }}>
											<FormattedMessage id="push.promotion.code.label" /> :{" "}
										</span>
										{promoCode}
									</div>
								</div>
							)}
							{note.map(noteLine => (
								<div className="push__line" key={noteLine}>
									<div className="push__note">{noteLine}</div>
								</div>
							))}
							{buttonUrl && (
								<div className="push__cta" style={{ textAlign: "center" }}>
									<a
										target="_blank"
										rel="noopener noreferrer"
										href={get(buttonUrl, "url")}
									>
										{get(buttonUrl, "buttonLabel")}
									</a>
								</div>
							)}
						</div>
					</Panel>
				</Collapse>
			</div>
		);
	}
}

PushNotificationComponent.propTypes = {
	backgroundColor: PropTypes.string,
	picto: PropTypes.string,
	title: PropTypes.arrayOf(PropTypes.string),
	content: PropTypes.arrayOf(PropTypes.string),
	promoCode: PropTypes.string,
	note: PropTypes.arrayOf(PropTypes.string),
	buttonUrl: PropTypes.shape({ url: PropTypes.string, buttonLabel: PropTypes.string }),
};

export const PushNotification = PushNotificationComponent;
