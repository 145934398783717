import { FETCH_PUSH_NOTIFICATIONS } from "app/pages/.shared/PushNotification/pushNotificationActionTypes";

import axios from "axios";
import env from "app/utils/env";
import { getStore } from "app/configureStore";

export const fetchPushNotifications = () => {
	const state = getStore().getState();
	const shop = state.shop;
	const endpoint = env("CONTENT_API_URL") || env("BASE_URL");

	return {
		type: FETCH_PUSH_NOTIFICATIONS,
		promise: axios.get(`${endpoint}/resources/${shop}/pushNotifications.json`),
	};
};
