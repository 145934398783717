import React from "react";
import RelativeLink from "./RelativeLink";
import PropTypes from "prop-types";
import { PRODUCTS_VISIBILITY, STRATEGY } from "app/constants";
import queryString from "query-string";
import isEmpty from "lodash/isEmpty";
import { strategyShape } from "app/utils/propTypes";

const ProductLink = ({
	handleProductClick,
	children,
	openOnNewTab,
	productsVisibility,
	productUri,
	strategy,
}) => {
	const newTabLink = openOnNewTab
		? {
				target: "_blank",
				rel: "noopener noferrer",
		  }
		: {};

	const to = {
		pathname: `/${productUri}`,
	};

	const search = {};

	// Persistence du mode preview de la listing vers la fiche produit pour la team production
	if (productsVisibility === PRODUCTS_VISIBILITY.PREVIEW) {
		search.preview = PRODUCTS_VISIBILITY.PREVIEW;
	}

	// si on ouvre dans un nouvel onglet, on doit persister le mode preview et la strategy
	if (openOnNewTab) {
		const strategyExists =
			strategy &&
			Object.keys(STRATEGY).some(key => {
				return STRATEGY[key] === strategy;
			});

		// Persistence de la strategy de la listing vers la fiche produit pour la team production
		if (strategyExists) {
			search.strategy = strategy;
		}
	}

	if (!isEmpty(search)) {
		to.search = queryString.stringify(search);
	}

	return (
		<RelativeLink design="primary" {...newTabLink} to={to} onClick={handleProductClick}>
			{children}
		</RelativeLink>
	);
};

ProductLink.propTypes = {
	handleProductClick: PropTypes.func,
	openOnNewTab: PropTypes.bool,
	productsVisibility: PropTypes.string,
	strategy: strategyShape,
	productUri: PropTypes.string,
};

export default React.memo(ProductLink);
