import { FETCH_PUSH_NOTIFICATIONS_SUCCESS } from "app/pages/.shared/PushNotification/pushNotificationActionTypes";
const initialState = { pushNotifications: [] };

export default (pushNotificationsState = initialState, action) => {
	switch (action.type) {
		case FETCH_PUSH_NOTIFICATIONS_SUCCESS:
			return { ...pushNotificationsState, pushNotifications: action.res.data };
		default:
			return pushNotificationsState;
	}
};
