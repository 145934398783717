import { connect } from "react-redux";
import {
	getQuoteTotalAvecFrais,
	isBookingEligibleForDeposit,
} from "app/pages/Booking/bookingSelectors";
import QuotationPriceSummary from "app/pages/.shared/PriceSummary/QuotationPriceSummary";
import { checkIfPaymentCasino4xDisponible } from "app/pages/Booking/Payment/paymentSelector";

const mapStateToProps = state => {
	return {
		hasChildren: state.booking.children > 0,
		isPayment4xDisponible: checkIfPaymentCasino4xDisponible(state),
		isBookingEligibleForDeposit: isBookingEligibleForDeposit(state),
		total: getQuoteTotalAvecFrais(state),
	};
};

export default connect(mapStateToProps)(QuotationPriceSummary);
