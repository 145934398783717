import React from "react";
import Popover from "react-popover";
import PropTypes from "prop-types";

class PopoverButton extends React.Component {
	constructor() {
		super();
		this.state = {
			isPopoverOpen: false,
		};
		this.closePopover = this.closePopover.bind(this);
		this.onButtonClick = this.onButtonClick.bind(this);
		this.handleBodyClick = this.handleBodyClick.bind(this);
	}

	closePopover() {
		this.setState({
			isPopoverOpen: false,
		});
	}

	onButtonClick() {
		const isPopoverOpen = this.state.isPopoverOpen;
		this.setState({
			isPopoverOpen: !isPopoverOpen,
		});
	}

	handleBodyClick() {
		if (this.props.closeOnBodyClick) {
			this.setState({
				isPopoverOpen: false,
			});
		}
	}

	render() {
		const {
			buttonLabel = false,
			popOverPosition = "below",
			popOverContent = false,
			popOverClassName,
			popOverContainerClassName,
			popOverButtonClassName,
			tipSize = 7,
			appendTarget,
		} = this.props;
		let popOverOptions = {};
		if (appendTarget) {
			popOverOptions = { appendTarget };
		}
		return (
			<div className={popOverContainerClassName}>
				<Popover
					enterExitTransitionDurationMs={300}
					className={popOverClassName}
					onOuterAction={this.closePopover}
					body={<div onClick={this.handleBodyClick}>{popOverContent}</div>}
					isOpen={this.state.isPopoverOpen}
					place={popOverPosition}
					tipSize={tipSize}
					{...popOverOptions}
				>
					<span onClick={this.onButtonClick} className={popOverButtonClassName}>
						{buttonLabel}
					</span>
				</Popover>
			</div>
		);
	}
}

PopoverButton.propTypes = {
	popOverClassName: PropTypes.string,
	popOverContainerClassName: PropTypes.string,
	popOverButtonClassName: PropTypes.string,
	popOverPosition: PropTypes.string,
	buttonLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	popOverContent: React.PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	tipSize: PropTypes.number,
	closeOnBodyClick: PropTypes.bool,
	appendTarget: PropTypes.element,
};

export default PopoverButton;
